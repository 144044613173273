<template>
  <div class="container" id="main-page">
    <div class="w-11/12 md:w-5/6 m-auto pt-4">
      <div class="container p-2">
        <div class="row justify-content-center row-header">
          <div class="col-12 container-back mb-3">
            <div class="history-header">
              <Back />
              <span> Syarat &amp; Ketentuan</span>
            </div>
          </div>
          <div class="col-12 about-us bg-white border-lg">
            <div>
              <div class="text_g">
                Syarat dan Ketentuan ini merupakan perjanjian antara pengguna
                (“Anda”) dan PT Solusi Desain Bangsa (“Kami”), yaitu sebuah
                perseroan terbatas yang didirikan dan beroperasi secara sah
                berdasarkan hukum Negara Republik Indonesia dan berdomisili di
                Sukoharjo, Indonesia. Syarat dan Ketentuan ini mengatur Anda
                saat mengakses dan menggunakan aplikasi, situs web
                (www.sekolahdesain.id dan situs web lain yang Kami kelola),
                fitur, teknologi, konten dan produk yang Kami sediakan
                (selanjutnya, secara Bersama-sama disebut sebagai “Platform”),
              </div>
              <br />
              <br />
              <div class="text_g">
                Harap membaca Syarat dan Ketentuan ini secara seksama sebelum
                Anda mulai menggunakan Platform Kami, karena peraturan ini
                berlaku pada penggunaan Anda terhadap Platform Kami.
              </div>
              <br />
              <br />
              <div class="text_g">
                Syarat dan Ketentuan ini mencakup hal-hal sebagai berikut:
              </div>
              <div class="text_g">
                <br />
                A.Pembukaan Akun Anda
                <br />
                B.Informasi Pribadi Anda
                <br />
                C.Akses terhadap Platform Kami
                <br />
                D.Penggunaan yang Dilarang
                <br />
                E.Hak Kekayaan Intelektual
                <br />
                F.Pengunggahan Konten Pada Platform Kami
                <br />
                G.Layanan Interaktif
                <br />
                H.Laporan Kemungkinan Pelanggaran
                <br />
                I.Tindakan yang Kami Anggap Perlu
                <br />
                J.Membuat Link ke Platform Kami
                <br />
                K.Link Pihak Ketiga Pada Platform Kami
                <br />
                L.Tanggung Jawab Anda
                <br />
                M.Batasan Tanggung Jawab Kami
                <br />
                N.Keadaan Kahar (Force Majeure)
                <br />
                O.Hukum yang Berlaku
                <br />
                P.Ketentuan Lainnya
                <br />
                Q.Cara Menghubungi Kami
                <br />
              </div>

              <div class="text_g">
                Anda harus berusia minimal 18 (delapan belas) tahun atau sudah
                menikah dan tidak berada di bawah perwalian atau pengampuan agar
                Anda secara hukum memiliki kapasitas dan berhak untuk
                mengikatkan diri pada Syarat dan Ketentuan ini. Jika Anda tidak
                memenuhi ketentuan tersebut,&nbsp;maka tindakan Anda mendaftar,
                mengakses, menggunakan atau melakukan aktivitas lain dalam
                Platform Kami harus dalam sepengetahuan, pengawasan dan
                persetujuan yang sah dari orang tua atau wali atau pengampu
                Anda.&nbsp;Orang tua, wali atau pengampu yang memberikan
                persetujuan bagi Anda yang berusia di bawah 18 (delapan belas)
                tahun bertanggung jawab secara penuh atas seluruh tindakan Anda
                dalam penggunaan dan akses terhadap Platform.
              </div>
              <br />
              <br />
              <div class="text_g">
                Dengan mendaftar dan/atau menggunakan Platform Kami, maka Anda
                dan/atau orang tua, wali atau pengampu Anda (jika Anda berusia
                di bawah 18 tahun) dianggap telah membaca, mengerti, memahami
                dan menyetujui semua isi dalam Syarat dan Ketentuan ini.
              </div>
              <div class="text_g">
                Dengan menyetujui Syarat dan Ketentuan ini, Anda juga menyetujui
                Syarat dan Ketentuan tambahan, termasuk Syarat dan Ketentuan
                atas setiap Layanan, dan perubahannya yang merupakan bagian yang
                tidak terpisahkan dari Syarat dan Ketentuan ini (selanjutnya,
                Syarat dan Ketentuan, Syarat dan Ketentuan tambahan, dan
                perubahannya secara bersama-sama disebut sebagai “Syarat dan
                Ketentuan”). Meskipun merupakan satu kesatuan, Syarat dan
                Ketentuan tambahan akan berlaku dalam hal terdapat perbedaan
                dengan Syarat dan Ketentuan.
              </div>
              <br />
              <br />
              <div class="text_g">
                Jika Anda tidak menyetujui Syarat dan Ketentuan ini, Kami berhak
                untuk menghentikan akses dan penggunaan Anda terhadap Platform
                Kami.
              </div>
              <br />
              <br />
              A.PEMBUKAAN AKUN ANDA
              <br />
              <br />
              <div class="text_g">
                Sebelum menggunakan Platform, Anda menyetujui Syarat dan
                Ketentuan ini dan Kebijakan Privasi, dan mendaftarkan diri Anda
                dengan memberikan informasi yang Kami butuhkan. Saat melakukan
                pendaftaran, Kami akan meminta Anda untuk memberikan nama
                lengkap, alamat surat elektronik dan nomor telepon genggam
                pribadi Anda. Kami juga dapat menghentikan penggunaan Platform
                jika dikemudian hari data-data yang Anda berikan kepada Kami
                terbukti tidak benar.
              </div>
              <br />
              <br />
              <div class="text_g">
                Sistem Kami akan membuatkan akun pada Platform (“Akun”) untuk
                Anda yang dapat digunakan untuk menggunakan Platform dan memesan
                layanan melalui Platform.
              </div>
              <br />
              <br />
              <div class="text_g">
                Dalam hal Anda telah keluar dari Akun Anda, maka Anda perlu
                memasukan alamat surat elektronik yang ada berikan pada saat
                mendaftarkan diri Anda dan memasukan password.
              </div>
              <br />
              <br />

              <div class="text_g">
                Akun Anda hanya dapat digunakan oleh Anda, sehingga Anda tidak
                dapat mengalihkannya kepada orang lain dengan alasan apa pun.
                Kami berhak menolak untuk memfasilitasi Layanan jika Kami
                mengetahui atau mempunyai alasan yang cukup untuk menduga bahwa
                Anda telah mengalihkan atau membiarkan Akun Anda digunakan oleh
                orang lain.
              </div>
              <br />
              <br />
              <div class="text_g">
                Keamanan dan kerahasiaan Akun Anda, termasuk nama terdaftar,
                alamat surat elektronik terdaftar, nomor telepon genggam
                terdaftar sepenuhnya merupakan tanggung jawab pribadi Anda.
                Segala kerugian dan risiko yang ada akibat kelalaian Anda dalam
                menjaga keamanan dan kerahasiaan sebagaimana disebutkan
                ditanggung oleh Anda dan/atau orang tua, wali atau pengampu Anda
                (jika Anda berusia di bawah 18 (delapan belas) tahun). Dengan
                demikian, Kami akan menganggap setiap penggunaan atau pesanan
                yang dilakukan melalui Akun Anda sebagai permintaan yang sah
                dari Anda. Anda harap segera memberitahukan kepada Kami jika
                Anda mengetahui atau menduga bahwa Akun Anda telah digunakan
                tanpa sepengetahuan dan persetujuan Anda. Kami akan melakukan
                tindakan yang Kami anggap perlu dan dapat Kami lakukan terhadap
                penggunaan tanpa persetujuan tersebut.
              </div>
              <br />
              <br />
              B.INFORMASI PRIBADI ANDA
              <br />
              <br />
              <div class="text_g">
                Pengumpulan, penyimpanan, pengolahan, penggunaan dan pembagian
                informasi pribadi Anda, seperti nama, alamat surat elektronik,
                dan nomor telepon genggam Anda yang Anda berikan ketika Anda
                membuka Akun tunduk pada Kebijakan Privasi, yang merupakan
                bagian yang tidak terpisahkan dari Syarat dan Ketentuan ini.
              </div>
              <br />
              <br />
              C.AKSES TERHADAP PLATFORM KAMI
              <br />
              <br />
              <div class="text_g">
                Kami tidak menjamin bahwa Platform Kami, maupun konten di
                dalamnya, akan selalu tersedia atau tanpa gangguan. Izin untuk
                mengakses Platform Kami bersifat sementara. Kami dapat
                menangguhkan, menarik, memberhentikan, maupun mengganti bagian
                mana pun dari Platform Kami tanpa pemberitahuan sebelumnya. Kami
                tidak bertanggung jawab atas alasan apa pun yang membuat
                Platform Kami tidak tersedia pada waktu atau periode tertentu.
              </div>
              <br />
              <br />
              <div class="text_g">
                Anda bertanggung jawab untuk membuat semua pengaturan yang
                dibutuhkan untuk mendapatkan akses terhadap Platform Kami.
              </div>
              <br />
              <br />
              D.PENGGUNAAN YANG DILARANG
              <br />
              <br />
              <div class="text_g">
                Anda hanya diperbolehkan menggunakan Platform kami untuk
                tujuan-tujuan yang sah menurut hukum. Anda tidak boleh
                menggunakan Platform Kami:
              </div>
              <br />
              <div class="text_g">
                a.Dengan cara-cara yang melanggar hukum dan peraturan lokal,
                nasional, maupun internasional yang berlaku.
              </div>
              <br />
              <div class="text_g">
                b.Dengan cara-cara yang melanggar hukum atau menipu, atau
                memiliki tujuan atau dampak yang melanggar hukum atau menipu.
              </div>
              <br />
              <div class="text_g">
                c.Untuk tujuan membahayakan atau mencoba mencelakakan anak di
                bawah umur dengan cara apa pun.
              </div>
              <br />
              <div class="text_g">
                d.Mengirim, secara sadar menerima, mengunggah, mengunduh,
                menggunakan, atau menggunakan kembali materi yang tidak sesuai
                dengan standar konten Kami.
              </div>
              <br />
              <div class="text_g">
                e.Menyebarkan atau mengirimkan materi iklan atau promosi yang
                tidak diinginkan atau tidak sah, serta bentuk permintaan serupa
                lainnya (seperti spam).
              </div>
              <br />
              <div class="text_g">
                Anda juga setuju: f.Dengan sengaja meneruskan data, mengirim
                atau mengunggah materi yang mengandung virus, trojan, worm,
                logic bomb, keystroke loggers, spyware, adware, maupun program
                berbahaya lainnya atau kode komputer sejenis yang dirancang
                untuk memberikan efek merugikan terhadap pengoperasian perangkat
                lunak atau perangkat keras apa pun.
              </div>
              <div class="text_g">Anda juga setuju:</div>
              <br />
              <div class="text_g">
                a.Untuk tidak mereproduksi, menggandakan, menyalin, atau menjual
                kembali bagian mana pun dari Platform Kami yang bertentangan
                dengan ketentuan dalam Syarat dan Ketentuan Platform Kami.
              </div>
              <br />
              <div class="text_g">
                b.Untuk tidak mengakses tanpa izin, mengganggu, merusak, atau
                mengacak-acak:
              </div>
              <br />
              <div class="text_g">i.bagian mana pun dari Platform Kami;</div>
              <br />
              <div class="text_g">
                ii.peralatan atau jaringan di mana Platform Kami tersimpan;
              </div>
              <br />
              <div class="text_g">
                iii.perangkat lunak apa pun yang digunakan dalam penyediaan
                Platform Kami; atau
              </div>
              <br />
              <div class="text_g">
                iv.peralatan atau jaringan atau perangkat lunak yang dimiliki
                oleh pihak ketiga mana pun. Apabila Anda adalah seorang pegawai
                negeri atau penyelenggara negara, dalam menggunakan Platform
                Kami, Anda setuju untuk mematuhi ketentuan peraturan
                perundang-undangan khususnya yang mengatur tentang penerimaan
                gratifikasi serta ketentuan lain yang berlaku di lingkungan
                lembaga anda.
              </div>
              <div class="text_g">
                Apabila Anda adalah seorang pendidik atau tenaga kependidikan,
                dalam menggunakan Platform Kami, anda setuju untuk mematuhi
                ketentuan peraturan perundang-undangan terkait pengelolaan dan
                penyelenggaraan pendidikan, terutama terkait kegiatan
                sosialisasi, pemasaran, distribusi, maupun penjualan
                program/produk dari Platform Kami kepada peserta didik di satuan
                pendidikan formal dimana Anda mengajar atau bekerja dengan suatu
                imbalan dalam bentuk apa pun.
              </div>
              <br />
              <br />
              E.HAK KEKAYAAN INTELEKTUAL
              <br />
              <br />
              <div class="text_g">
                Platform kami, termasuk namun tidak terbatas pada, nama, logo,
                kode program, desain, merek dagang, teknologi, basis data,
                proses dan model bisnis, dilindungi oleh hak cipta, merek, paten
                dan hak kekayaan intelektual lainnya yang tersedia berdasarkan
                hukum Republik Indonesia yang terdaftar atas nama Kami. Kami
                memiliki seluruh hak dan kepentingan atas Platform, termasuk
                seluruh hak kekayaan intelektual terkait dengan seluruh fitur
                yang terdapat didalamnya dan hak kekayaan intelektual terkait.
              </div>
              <br />
              <br />
              <div class="text_g">
                Anda dapat mengunduh ekstrak dari halaman tertentu dari Platform
                Kami untuk kegunaan pribadi selama masa berlangganan Anda.
              </div>
              <div class="text_g">
                Anda tidak boleh mengubah salinan dalam bentuk kertas maupun
                digital dari materi apa pun yang telah Anda cetak atau unduh
                dengan cara apa pun, dan Anda tidak boleh menggunakan ilustrasi,
                foto-foto, potongan video atau audio, maupun grafis lain secara
                terpisah dari teks pendampingnya.
              </div>
              Anda dilarang untuk:
              <br />
              <div class="text_g">
                1.menyalin, mengubah, mencetak, mengadaptasi, menerjemahkan,
                menciptakan karya tiruan dari, mendistribusikan, memberi
                lisensi, menjual, memindahkan, menampilkan secara publik,
                menunjukkan secara publik, menggandakan, mengirimkan, menyiarkan
                lewat media online maupun offline, memotong, membongkar, atau
                sebaliknya mengeksploitasi bagian mana pun dari Platform Kami,
                termasuk namun tidak terbatas pada konten-konten dan
                materi-materi berbayar pada Platform, baik secara fisik maupun
                digital, dalam masa berlangganan maupun selesai berlangganan
                Platform;
              </div>
              <br />
              <div class="text_g">
                2.memberi lisensi, lisensi turunan, menjual, menjual ulang,
                memindahkan, menetapkan, mendistribusikan, atau sebaliknya
                mengeksploitasi atau membagikan secara komersial Platform Kami
                dan/atau perangkat lunak lain yang terasosiasi dengan Platform
                Kami dengan cara apa pun;
              </div>
              <br />
              <div class="text_g">
                3.menciptakan ‘link’ internet menuju situs web Kami, atau
                menyadur (frame), atau mengkomputasi (mirror) perangkat lunak
                mana pun pada server (server) atau perangkat nirkabel atau
                perangkat yang terhubung ke internet lainnya;
              </div>
              <br />
              <div class="text_g">
                4.merekayasa balik atau mengakses Platform Kami guna (a)
                membangun produk atau jasa yang kompetitif, (b) membangun produk
                berdasarkan ide, fitur, fungsi, maupun grafis yang serupa dengan
                Platform Kami, atau (c) menyalin ide, fitur, fungsi, atau grafis
                pada Platform Kami;
              </div>
              <br />
              <div class="text_g">
                5.meluncurkan program atau skrip otomatis termasuk, namun tidak
                terbatas pada, web spider, web crawler, robot web, web ant,
                pengindeksan web, bot, virus, worm, atau program apa pun yang
                dapat meningkatkan permintaan server per detik, atau membuat
                beban terlalu berat yang mengganggu operasi dan/atau kinerja
                Platform Kami;
              </div>
              <br />
              <div class="text_g">
                6.menggunakan robot, spider, aplikasi pencarian atau pengambilan
                situs, maupun perlengkapan dan proses manual atau otomatis lain
                untuk mengambil, membuat indeks, menambang data, atau dengan
                cara apa pun menggandakan atau menghindari struktur navigasi
                atau tampilan Platform Kami maupun kontennya;
              </div>
              <br />
              <div class="text_g">
                7.memasang, mendistribusikan, atau menggandakan dengan cara apa
                pun materi dengan hak cipta, merek dagang, atau informasi
                kepemilikan lain tanpa sebelumnya memperoleh persetujuan dari
                pemilik hak kepemilikan;
              </div>
              <br />
              <div class="text_g">
                8.menghapus pemberitahuan hak cipta, merek dagang, atau hak
                kepemilikan lainnya yang terkandung dalam Platform Kami. Tidak
                ada lisensi atau hak yang diberikan kepada Anda secara implisit
                atau sebaliknya berdasarkan hak kekayaan intelektual yang
                dimiliki atau dikendalikan oleh kami dan pemberi lisensi kami,
                kecuali lisensi dan hak yang secara tersurat diberikan dalam
                Persyaratan Penggunaan ini. Anda tidak boleh menggunakan bagian
                mana pun dari konten pada Platform Kami untuk tujuan komersial
                tanpa sebelumnya memperoleh lisensi untuk melakukannya dari Kami
                atau pemberi lisensi Kami.
              </div>
              <div class="text_g">
                Anda (i) tidak diperbolehkan mengirimkan spam atau pesan
                duplikatif yang tidak diinginkan dan melanggar Peraturan yang
                Berlaku; (ii) tidak diperbolehkan mengirim atau menyimpan materi
                yang mengganggu, bersifat asusila, mengancam, mencemarkan nama
                baik, maupun tidak mematuhi hukum serta membahayakan, termasuk
                namun tidak terbatas pada materi yang berbahaya bagi anak-anak
                atau melanggar hak privasi pihak ketiga; (iii) tidak
                diperbolehkan mengirim materi yang mengandung virus perangkat
                lunak seperti worm dan trojan, serta kode, berkas digital, kode
                skrip, agen, maupun program komputer lain yang berbahaya; (iv)
                tidak diperbolehkan mengganggu dan mengacaukan integritas atau
                kinerja Platform Kami serta data yang dimuat di dalamnya; (v)
                tidak diperbolehkan berusaha memperoleh akses yang tidak sah ke
                dalam Platform Kami maupun sistem dan jaringan terkait; dan (vi)
                tidak diperbolehkan menyamar sebagai individu atau entitas lain
                yang tidak mencerminkan afiliasi Anda sebenarnya dengan
                seseorang atau suatu entitas.
              </div>
              <div class="text_g">
                Status Kami (dan status kontributor lain yang telah Kami
                identifikasi) sebagai penulis dari konten pada situs web Kami
                harus selalu diakui.
              </div>
              <br />
              <div class="text_g">
                Jika Anda mencetak, menyalin, atau mengunduh bagian mana pun
                dari Platfrom Kami yang melanggar Persyaratan Penggunaan ini,
                hak Anda untuk menggunakan Platform Kami akan segera
                diberhentikan dan Anda harus, berdasarkan keputusan Kami,
                mengembalikan atau memusnahkan salinan dari materi yang telah
                Anda buat.
              </div>
              <div class="text_g">
                Kami berhak untuk melakukan investigasi maupun menuntut bentuk
                pelanggaran apa pun terhadap Persyaratan Penggunaan di atas
                sesuai dengan ketentuan hukum yang berlaku. Kami dapat
                melibatkan dan bekerja sama dengan pihak yang berwenang dalam
                menuntut pengguna yang melanggar Persyaratan Penggunaan ini.
                Anda mengakui bahwa Kami tidak diwajibkan untuk mengawasi akses
                Anda terhadap Platform Kami, tapi Kami berhak untuk melakukannya
                dengan tujuan mengoperasikan Platform Kami, memastikan kepatuhan
                Anda dengan Syarat dan Ketentuan ini, atau untuk menaati hukum
                yang berlaku atau keputusan pengadilan, lembaga administratif,
                maupun badan pemerintahan lainnya.
              </div>
              <br />

              F. PENGUNGGAHAN KONTEN PADA PLATFORM KAMI
              <br />
              <div class="text_g">
                Anda dapat menggunakan fitur yang memungkinkan Anda mengunggah
                konten maupun materi ke Platform Kami, atau untuk berhubungan
                dengan pengguna Platform Kami lainnya melalui layanan interaktif
                (untuk selanjutnya disebut sebagai “Konten”). Konten harus:
              </div>
              <div class="text_g">
                <br />
                1.Akurat (di mana mereka menyatakan fakta);
                <br />
                2.Benar-benar dimaksudkan (di mana mereka menyatakan opini); dan
                <br />
                3.Mematuhi hukum yang berlaku di Indonesia dan di negara dari
                mana mereka dituliskan. Konten tidak boleh:
                <br />
                1.Mengandung materi yang mencemarkan nama baik seseorang;
                <br />
                2.Mengandung materi yang tidak senonoh, menyinggung, bersifat
                kebencian, atau menghasut;
                <br />
                3.Mempromosikan konten yang berkaitan dengan perjudian, undian,
                dan/atau taruhan;
                <br />
                4.Mempromosikan materi yang eksplisit secara seksual;
                <br />
                5.Mempromosikan kekerasan;
                <br />
                6.Mempromosikan diskriminasi berdasarkan ras, jenis kelamin,
                agama, kebangsaan, kecacatan, orientasi seksual, atau usia;
                <br />
                7.Mempromosikan layanan meretas (hacking) dan/atau membobol
                (cracking);
                <br />
                8.Mempromosikan akses terhadap produk penipuan, pencucian uang,
                skema pemasaran berjenjang (multi-level marketing), serta produk
                bajakan;
                <br />
                9.Mempromosikan akses terhadap perdagangan manusia dan organ
                tubuh manusia;
                <br />
                10.Mempromosikan akses terhadap zat-zat terlarang dan narkotika;
                <br />
                11.Mempromosikan akses terhadap rokok dan materi berbahan
                tembakau;
                <br />
                12.Mempromosikan penjualan tidak sah terhadap produk-produk yang
                membutuhkan lisensi (misalnya obat-obatan, bahan peledak,
                senjata api, dll.);
                <br />
                13.Melanggar hak cipta, hak pangkalan data (database right),
                atau pun merek dagang orang lain;
                <br />
                14.Dapat menipu seseorang;
                <br />
                15.Dibuat dengan melanggar kewajiban hukum apa pun kepada pihak
                ketiga, seperti kewajiban kontrak atau kewajiban menjaga
                kerahasiaan;
                <br />
                16.Mempromosikan aktivitas melanggar hukum apa pun;
                <br />
                17.Mengancam, menyalahgunakan, atau menyerang privasi orang
                lain, atau menyebabkan gangguan, ketidaknyamanan, maupun
                kecemasan yang tidak perlu;
                <br />
                18.Dapat melecehkan, menyinggung, mempermalukan, membuat
                khawatir, atau mengganggu siapa pun;
                <br />
                19.Digunakan untuk menyamar sebagai orang lain, atau untuk
                memalsukan identitas atau afiliasi Anda dengan orang lain;
                <br />
                20.Memberi kesan bahwa Konten tersebut berasal dari Kami, ketika
                tidak benar demikian; dan
                <br />

                21.Mengadvokasi, mempromosikan, atau membantu tindakan yang
                melanggar hukum seperti (hanya sebagai contoh) pelanggaran hak
                cipta atau penyalahgunaan komputer. Anda menjamin bahwa Konten
                tersebut telah mematuhi standar yang telah disebutkan, dan bahwa
                Anda akan bertanggung jawab secara penuh kepada Kami dan
                mengganti kerugian Kami atas pelanggaran terhadap jaminan
                tersebut. Dengan demikian, Anda akan bertanggung jawab untuk
                segala kerugian atau kerusakan yang Kami derita akibat
                pelanggaran jaminan Anda.
              </div>
              <br />
              <div class="text_g">
                Seluruh Konten yang Anda unggah ke Platform Kami akan dianggap
                bersifat tidak rahasia, tidak bersifat kepemilikan dan tidak
                melanggar hak kekayaan intelektual dari pihak manapun, kecuali
                Anda nyatakan sebaliknya kepada Kami sebagaimana dijelaskan
                dalam Kebijakan Privasi Platform Kami. Anda tetap memiliki semua
                hak kepemilikan terhadap Konten Anda, namun Anda diharuskan
                untuk memberi Kami dan pengguna Platform Kami lainnya lisensi
                terbatas untuk menggunakan, menyimpan, dan menyalin Konten
                tersebut, serta untuk mendistribusikan dan membuatnya tersedia
                bagi pihak ketiga. Kami juga berhak menyingkap identitas Anda
                kepada pihak ketiga mana pun yang mengklaim bahwa Konten yang
                Anda pampang atau unggah merupakan pelanggaran terhadap hak
                kekayaan intelektual mereka, atau hak privasi mereka.
              </div>
              <div class="text_g">
                Kami tidak bertanggung jawab atau dikenakan kewajiban oleh pihak
                ketiga mana pun, untuk Konten atau akurasi dari Konten mana pun
                yang Anda pampang atau pengguna Platform Kami lainnya.
              </div>
              <div class="text_g">
                Kami berhak menghapus setiap Konten yang Anda buat pada Platform
                Kami jika, menurut pendapat Kami, konten Anda tidak sesuai
                dengan standar konten yang ditetapkan dalam Syarat dan Ketentuan
                Kami.
              </div>
              <div class="text_g">
                Pandangan yang diungkapkan oleh pengguna lain pada Platform Kami
                tidak mewakili pandangan maupun nilai-nilai Kami.
              </div>
              <div class="text_g">
                Anda sepenuhnya bertanggung jawab untuk mengamankan dan membuat
                cadangan dari Konten Anda.
              </div>
              <br />
              F.LAYANAN INTERAKTIF
              <br />
              <div class="text_g">
                Dari waktu ke waktu, Kami dapat menyediakan layanan interaktif
                pada Platform Kami, termasuk namun tidak terbatas pada Forum,
                Live Webinar, Live Chat, Schedule, Homeworks, dan Events (untuk
                selanjutnya disebut sebagai “layanan interaktif”).
              </div>
              <div class="text_g">
                Pada situasi di mana Kami menyediakan layanan interaktif, Kami
                akan menyediakan informasi yang jelas kepada Anda tentang jenis
                layanan yang ditawarkan. Kami akan memberikan usaha terbaik
                untuk menilai risiko yang mungkin timbul bagi pengguna (terutama
                untuk pengguna yang berusia di bawah 18 (delapan belas) tahun)
                dari pihak ketiga ketika mereka menggunakan layanan interaktif
                yang tersedia pada Platform Kami. Akan tetapi, Kami tidak
                berkewajiban untuk mengawasi, memantau, atau memoderasi layanan
                interaktif mana pun pada Platform Kami, dan Kami secara tegas
                mengecualikan pertanggungjawaban Kami atas kerugian atau
                kerusakan yang dapat timbul dari penggunaan layanan interaktif
                oleh pengguna yang bertentangan dengan ketentuan standar konten
                Kami.
              </div>
              <div class="text_g">
                Penggunaan layanan interaktif Kami oleh Anda yang berusia di
                bawah 18 (delapan belas) tahun bergantung pada pengawasan dan
                persetujuan orang tua atau wali mereka. Kami menganjurkan orang
                tua yang mengawasi dan mengizinkan anak-anak mereka untuk
                menggunakan layanan interaktif untuk mengkomunikasikan tentang
                keamanan anak-anak mereka secara online. Orang tua atau wali
                dari Anda yang berusia di bawah 18 (delapan belas) tahun yang
                menggunakan layanan interaktif harus mengetahui atas potensi
                risiko yang dapat Anda alami pada layanan interaktif dan
                bertanggung jawab secara penuh atas segala tindakan Anda.
              </div>
              <br />
              H. LAPORAN KEMUNGKINAN PELANGGARAN
              <br />
              <div class="text_g">
                Jika Anda menemukan konten apa pun pada Platform Kami yang Anda
                yakini melanggar hak cipta apa pun, menyalahi hak lainnya,
                merusak nama baik, bersifat pornografis atau tidak senonoh,
                rasis, atau dengan cara- cara lain menyebabkan pelanggaran
                secara luas, atau yang merupakan peniruan identitas,
                penyalahgunaan, spam, atau sebaliknya menyalahi Persyaratan
                Penggunaan serta Kebijakan Privasi maupun Peraturan yang Berlaku
                lainnya, silakan laporkan ini kepada Kami melalui surat
                elektronik ke admin@sekolahdesain.id dan/atau melalui surat ke
                alamat berikut: SEKOLAHDESAIN.ID HQ, Office Park B-19, The Park
                Solobaru, Sukoharjo 57552
              </div>
              <div class="text_g">
                Pastikan bahwa Anda menyertakan, dalam laporan tersebut
                (“Laporan”), informasi sebagai berikut:
                <br />
                1.Pernyataan bahwa Anda telah mengidentifikasi konten yang
                melanggar atau menyalahi Syarat dan Ketentuan dan Kebijakan
                Privasi Platform Kami maupun Peraturan yang Berlaku lainnya pada
                Platform Kami;
                <br />
                2.Deskripsi dari pelanggaran atau konten ilegal serta link di
                mana konten tersebut berada;
                <br />
                3.Screenshot dari konten yang melanggar atau bersifat ilegal;
                <br />
                4.Nama lengkap, alamat dan nomor telepon Anda, alamat surat
                elektronik di mana Anda dapat dihubungi, serta nama pengguna
                (username) Akun Anda jika Anda memilikinya. Dengan membuat
                Laporan, Anda akan dianggap telah menyertakan, dalam Laporan
                tersebut:
                <br />
                1.pernyataan bahwa Anda dengan niatan baik percaya bahwa
                penggunaan materi yang disengketakan tersebut tidak diizinkan
                oleh pemilik hak cipta, agennya, maupun hukum;
                <br />
                2.pernyatan bahwa informasi dalam Laporan tersebut adalah
                akurat; dan
                <br />
                3.pada kasus di mana Anda melaporkan konten yang Anda yakini
                melanggar hak cipta atau hak-hak terkait lainnya, pernyataan
                bahwa Anda berwenang untuk bertindak atas nama pemilik hak cipta
                atau hak-hak lain yang diduga telah dilanggar.
                <br />
              </div>
              I.TINDAKAN YANG KAMI ANGGAP PERLU
              <br />
              <div class="text_g">
                Apabila Kami mengetahui atau mempunyai alasan yang cukup untuk
                menduga bahwa Anda telah melakukan tindakan asusila,
                pelanggaran, kejahatan atau tindakan lain yang bertentangan
                dengan Syarat dan Ketentuan ini dan/atau peraturan
                perundang-undangan yang berlaku, baik yang dirujuk dalam Syarat
                dan Ketentuan ini atau tidak, maka Kami berhak untuk dan dapat
                membekukan Akun, baik sementara atau permanen, atau menghentikan
                akses Anda terhadap Platform, melakukan pemeriksaan, menuntut
                ganti kerugian, melaporkan kepada pihak berwenang dan/atau
                mengambil tindakan lain yang kami anggap perlu, termasuk
                tindakan hukum pidana maupun perdata.
              </div>
              <div class="text_g">
                Kami akan menindaklanjuti dengan melakukan investigasi dan/atau
                memfasilitasi Penyedia Layanan yang bersangkutan untuk
                melaporkan kepada pihak yang berwajib apabila Kami menerima
                Laporan adanya pelanggaran yang Anda lakukan atas Syarat dan
                Ketentuan ini ataupun pelanggaran terhadap peraturan
                perundang-undangan yang berlaku, sehubungan dengan pelecehan
                atau kekerasan verbal, termasuk namun tidak terbatas pada, atas
                fisik, jenis kelamin, suku, agama dan ras.
              </div>
              <br />
              J. MEMBUAT LINK KE PLATFORM KAMI
              <br />
              <div class="text_g">
                Anda dapat membuat link ke Platform Kami, asalkan Anda
                melakukannya dengan cara yang adil dan legal serta tidak merusak
                reputasi Kami atau mengambil keuntungan darinya.
              </div>
              <div class="text_g">
                Anda tidak diperbolehkan membuat link sedemikian rupa sehingga
                memberi kesan adanya suatu asosiasi, persetujuan, ataupun
                dukungan dari Kami ketika hal tersebut tidak benar adanya.
              </div>
              <div class="text_g">
                Anda tidak diperbolehkan membuat link ke Platform Kami pada
                situs web mana pun yang tidak dimiliki oleh Anda. Kami berhak
                mencabut izin pembuatan link tanpa pemberitahuan. Situs web yang
                Anda berikan link harus mematuhi segala aspek standar konten
                yang telah ditetapkan melalui Syarat dan Ketentuan Kami. Jika
                Anda ingin menggunakan konten pada Platform Kami selain melalui
                hal-hal yang diatur diatas, silakan hubungi:
                admin@sekolahdesain.id
              </div>
              <br />
              K. LINK PIHAK KETIGA PADA PLATFORM KAMI
              <br />
              <div class="text_g">
                Di mana Platform Kami mengandung link and akses ke situs web dan
                konten lain yang disediakan oleh Pihak Ketiga. Kami tidak
                memiliki kontrol atas konten situs web atau sumber daya
                tersebut. Kami tidak bertanggung jawab atas bagian apapun dari
                isi konten yang disediakan oleh Pihak Ketiga. Akses atau
                penggunaan Anda terhadap konten Pihak Ketiga tersebut merupakan
                bentuk persetujuan Anda untuk tunduk pada Syarat dan Ketentuan
                dan kebijakan privasi yang ditetapkan oleh Pihak Ketiga
                tersebut.
              </div>
              <br />
              L. TANGGUNG JAWAB ANDA
              <br />
              <div class="text_g">
                Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk
                menggunakan atau mengakses Platform. Anda harus berperilaku
                dengan hormat dan tidak boleh terlibat dalam perilaku atau
                tindakan yang tidak sah, mengancam atau melecehkan ketika
                menggunakan Platform.
              </div>
              <div class="text_g">
                Anda bertanggung jawab secara penuh atas setiap kerugian
                dan/atau klaim yang timbul dari penggunaan Platform melalui Akun
                Anda, baik oleh Anda atau pihak lain yang menggunakan Akun Anda,
                dengan cara yang bertentangan dengan Syarat dan Ketentuan ini,
                Kebijakan Privasi atau peraturan perundang-undangan yang
                berlaku, termasuk namun tidak terbatas untuk tujuan anti
                pencucian uang, anti pendanaan terorisme, aktivitas kriminal,
                penipuan dalam bentuk apapun (termasuk namun tidak terbatas pada
                kegiatan phishing dan/atau social engineering), pelanggaran hak
                kekayaan intelektual, dan/atau aktivitas lain yang merugikan
                publik dan/atau pihak lain manapun atau yang dapat atau dianggap
                dapat merusak reputasi Kami.
              </div>
              <div class="text_g">
                Kami tidak menjamin bahwa Platform Kami akan aman atau terbebas
                dari bug atau virus. Anda bertanggung jawab untuk mengatur
                teknologi informasi, program komputer, serta platform yang Anda
                gunakan untuk mengakses Platform Kami. Anda harus menggunakan
                perangkat lunak anti virus Anda sendiri.
              </div>
              <br />
              M. BATASAN TANGGUNG JAWAB KAMI
              <br />
              <div class="text_g">
                Platform yang Kami sediakan adalah sebagaimana adanya dan Kami
                tidak menyatakan atau menjamin bahwa keandalan, ketepatan waktu,
                kualitas, kesesuaian, ketersediaan, akurasi, kelengkapan atau
                keamanan dari Platform dapat memenuhi kebutuhan dan akan sesuai
                dengan harapan Anda.
              </div>
              <div class="text_g">
                Kami tidak bertanggung jawab kepada pengguna mana pun atas
                kerugian atau kerusakan, baik dalam bentuk kontrak, perbuatan
                melawan hukum (termasuk kelalaian), pelanggaran kewajiban
                berdasarkan undang-undang, atau sebaliknya, meskipun dapat
                diperkirakan, yang terjadi di bawah atau berhubungan dengan:
                <br />
                1.Penggunaan, atau ketidakmampuan untuk menggunakan, Platform
                Kami; atau
                <br />
                2.Penggunaan atau kepercayaan terhadap konten apa pun yang
                ditampilkan pada Platform Kami.
              </div>
              <br />
              <div class="text_g">
                Kami hanya menyediakan Platform untuk penggunaan domestik dan
                pribadi. Anda setuju untuk tidak menggunakan Platform Kami untuk
                tujuan komersial atau bisnis apa pun, dan Kami tidak bertanggung
                jawab kepada Anda atas kerugian, kehilangan usaha, gangguan
                usaha, maupun hilangnya kesempatan bisnis.
              </div>
              <div class="text_g">
                Kami tidak bertanggung jawab atas kerugian atau kerusakan yang
                disebabkan oleh virus, serangan Penolakan Layanan secara
                Terdistribusi (DDoS), maupun materi teknologi berbahaya lainnya
                yang dapat menginfeksi peralatan komputer, program komputer,
                data, atau materi kepemilikan lainnya karena penggunaan maupun
                pengunduhan konten apa pun dari Platform Kami maupun situs web
                lain yang terkait dengannya oleh Anda.
              </div>
              <div class="text_g">
                Kami tidak berkewajiban untuk mengawasi akses atau penggunaan
                Anda terhadap Platform. Akan tetapi, Kami akan tetap melakukan
                pengawasan untuk memastikan kelancaran penggunaan Platform dan
                untuk memastikan kepatuhan Anda terhadap Syarat dan Ketentuan
                ini, peraturan perundang-undangan yang berlaku, putusan
                pengadilan, dan/atau ketentuan lembaga administratif atau badan
                pemerintahan lainnya.
              </div>
              <div class="text_g">
                Kami tidak bertanggung jawab atas konten situs web yang
                terhubung dengan Platform Kami. Tautan semacam itu seharusnya
                tidak ditafsirkan sebagai bentuk dukungan Kami terhadap
                situs-situs tersebut. Kami tidak bertanggung jawab atas kerugian
                atau kerusakan apa pun yang timbul dari penggunaan Anda terhadap
                situs-situs web tersebut.
              </div>
              <br />
              N. KEADAAN KAHAR (FORCE MAJEURE)
              <br />
              <div class="text_g">
                Platform Kami dapat diinterupsi oleh kejadian di luar kewenangan
                atau kontrol Kami (“Keadaan Kahar”), termasuk namun tidak
                terbatas pada bencana alam, gangguan listrik, gangguan
                telekomunikasi, kebijakan pemerintah, dan lain-lain. Anda setuju
                untuk membebaskan Kami dari setiap tuntutan dan tanggung jawab,
                jika Kami tidak dapat memfasilitasi Layanan, termasuk memenuhi
                instruksi yang Anda berikan melalui Platform, baik sebagian
                maupun seluruhnya, karena suatu Keadaan Kahar.
              </div>
              <br />
              O. HUKUM YANG BERLAKU
              <br />
              <div class="text_g">
                Syarat dan Ketentuan ini diatur berdasarkan hukum Republik
                Indonesia. Setiap dan seluruh perselisihan yang timbul dari
                penggunaan Platform tunduk pada yurisdiksi eksklusif Pengadilan
                Negeri Jakarta Selatan.
              </div>
              <br />
              P. KETENTUAN LAINNYA
              <br />
              <div class="text_g">
                Anda mengerti dan setuju bahwa Syarat dan Ketentuan ini
                merupakan perjanjian dalam bentuk elektronik dan tindakan Anda
                menekan tombol ‘daftar’ saat pembukaan Akun atau tombol ‘masuk’
                saat akan mengakses Akun Anda merupakan persetujuan aktif Anda
                untuk mengikatkan diri dalam perjanjian dengan Kami sehingga
                keberlakuan Syarat dan Ketentuan ini dan Kebijakan Privasi
                adalah sah dan mengikat secara hukum dan terus berlaku sepanjang
                penggunaan Platform oleh Anda.
              </div>
              <div class="text_g">
                Kami dapat merevisi Persyaratan Penggunaan ini kapan pun dengan
                mengubah halaman ini. Mohon kunjungi kembali halaman ini dari
                waktu ke waktu guna memperhatikan ada tidaknya perubahan yang
                Kami buat, karena perubahan tersebut akan mengikat Anda.
              </div>
              <div class="text_g">
                Kami dapat memperbaharui Platform dari waktu ke waktu serta
                mengubah kontennya kapan pun. Meskipun demikian, perlu diketahui
                bahwa Platform Kami dapat memiliki konten yang tidak diperbarui
                pada waktu tertentu, dan Kami tidak bertanggung jawab untuk
                memperbaruinya. Kami tidak menjamin bahwa Platform Kami, maupun
                konten yang terkandung di dalamnya, dapat bebas sepenuhnya dari
                kesalahan atau kelalaian.
              </div>
              <div class="text_g">
                Anda tidak akan mengajukan tuntutan atau keberatan apapun
                terhadap keabsahan dari Syarat dan Ketentuan atau Kebijakan
                Privasi yang dibuat dalam bentuk elektronik. Anda tidak dapat
                mengalihkan hak Anda berdasarkan Syarat dan Ketentuan ini tanpa
                persetujuan tertulis sebelumnya dari Kami. Namun, Kami dapat
                mengalihkan hak Kami berdasarkan Syarat dan Ketentuan ini setiap
                saat kepada pihak lain tanpa perlu mendapatkan persetujuan
                terlebih dahulu dari atau memberikan pemberitahuan sebelumya
                kepada Anda.
              </div>
              <div class="text_g">
                Bila Anda tidak mematuhi atau melanggar ketentuan dalam Syarat
                dan Ketentuan ini, dan Kami tidak mengambil tindakan secara
                langsung, bukan berarti Kami mengesampingkan hak Kami untuk
                mengambil tindakan yang diperlukan di kemudian hari.
              </div>
              <div class="text_g">
                Ketentuan ini tetap berlaku bahkan setelah pembekuan sementara,
                pembekuan permanen, penghapusan Platform atau setelah
                berakhirnya perjanjian ini antara Anda dan Kami.
              </div>
              <div class="text_g">
                Jika salah satu dari ketentuan dalam Syarat dan Ketentuan ini
                tidak dapat diberlakukan, hal tersebut tidak akan memengaruhi
                ketentuan lainnya.
              </div>
              <br />
              Q. CARA MENGHUBUNGI KAMI
              <br />
              <div class="text_g">
                Untuk menghubungi kami, silakan kirim surat elektronik ke
                admin@sekolahdesain.id. Saya dan/atau orang tua, wali atau
                pengampu saya telah membaca dan mengerti seluruh Syarat dan
                Ketentuan ini dan konsekuensinya dan dengan ini menerima setiap
                hak, kewajiban, dan ketentuan yang diatur di dalamnya.
                <p></p>
              </div>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.border-lg {
  border-radius: 1rem;
}
</style>
